<template>
  <div class="chart-box" style="z-index: 9999999; heigth: 100vh">
    <div ref="commonChart" style="height: 520px; width: 380px"></div>
  </div>
</template>
<script>
export default {
  props: {
    chartData: {
      type: Array,
      require: false,
    },
    total: {
      type: String,
      require: false,
    },
  },
  data() {
    return {
      pieData: [],
      count: 0,
      lc: "",
    };
  },
  methods: {
    formatNum(value) {
      if (!value && value !== 0) return 0;

      let str = value.toString();
      let reg =
        str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    },
    initChart() {
      this.count++;
      let myChart = this.$echarts.init(this.$refs.commonChart);
      myChart.clear();

      this.pieData = this.chartData;
      const list = this.pieData;
      let that = this;
      // console.log('v list==>', list)
      // const vu = list.map((item) => item.value)
      // console.log('vu==>', vu)

      let option = {
        tooltip: {
          trigger: "item",
          formatter: function (p) {
            //  console.log(p,a)
            // return `${p.seriesName}</br>${p.name}</br><b>$${that.formatNum(
            //   p.value
            // )}</b>`;
             return `${p.name}</br><b>$${that.formatNum(
              p.value
            )}</b>`;
          },
          // formatter: '{a}</br>{b}</br><b>${c}</b>',
        },
        title: {
          text: that.$t("Dashboard.ChartTitle"),
          textAlign: "center",
          left: "48%",
          top: "5%",
        },
        // color: ['red', 'yellow', 'blue', 'green', 'purple'],
        color: [
          new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 0,
              color: "#65d161",
            },
            {
              offset: 1,
              color: "#8ae587",
            },
          ]),
        ],
        legend: {
          // itemWidth: 2,
          // itemHeight: 8,
          //   icon: "circel",
          //   x: "right",
          //   y: "center",
          orient: "verticel",
          // align: "bottom",
          // left: "center",
          // y: "bottom",
          bottom: "20px",
          left: "15%",
          // x: "center",
          // top: "32%",
          // top: "88%",
          // top: "middle",
          itemGap: 15,
          //   padding: [0, 0, 0, 0],
        },
        graphic: [
          {
            type: "group",
            left: "center",
            top: "35%",
            children: [
              {
                type: "text",
                style: {
                  text: that.$t("Dashboard.TotalSpend"),
                  textAlign: "center",
                  fill: "#333333",
                },
              },
            ],
          },
          {
            type: "group",
            left: "center",
            top: "40%",
            children: [
              {
                type: "text",
                style: {
                  text: "$" + that.formatNum(that.total),
                  textAlign: "center",
                  fill: "#333333",
                  fontSize: "18px",
                  fontWeight: "bold",
                },
              },
            ],
          },
        ],
        series: [
          {
            // name: that.$t("Dashboard.Merchant"),
            name: "",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              // formatter: "$"
            },
            emphasis: {
              label: {
                // show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: list,
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  created() {
    this.lc = this.$i18n.locale;
  },
  mounted() {
    this.initChart();
    console.log("locs==>", this.$i18n.locale);
  },
  watch: {
    total() {
      this.initChart();
    },
    "$i18n.locale"(newV) {
      console.log("newV==>", newV);
      this.initChart();
    },
  },
};
</script>