<template>
  <!-- class="justify-content-md-center" -->
  <CRow :xs="{ gutterX: 5 }" class="lg-p sm-qu-padding">
    <!-- <template v-if="!noCharts">
      <CCol md="3" sm="6">
        <CWidgetBrand
          color="facebook"
          right-header="89k"
          right-footer="friends"
          left-header="459"
          left-footer="feeds"
        >
          <CIcon
            name="cib-facebook"
            height="52"
            class="my-4"
          />
          <CChartLineSimple
            class="c-chart-brand"
            background-color="rgba(255,255,255,.1)"
            :data-points="[65, 59, 84, 84, 51, 55, 40]"
            label="Friends"
            labels="months"
          />
        </CWidgetBrand>
       </CCol>
       <CCol md="3" sm="6">
        <CWidgetBrand
          color="twitter"
          right-header="973k"
          right-footer="followers"
          left-header="1.792"
          left-footer="tweets"
        >
          <CIcon
            name="cib-twitter"
            height="52"
            class="my-4"
          />
          <CChartLineSimple
            class="c-chart-brand"
            background-color="rgba(255,255,255,.1)"
            :data-points="[1, 13, 9, 17, 34, 41, 38]"
            label="Followers"
            labels="months"
          />
        </CWidgetBrand>
       </CCol>
       <CCol md="3" sm="6">
        <CWidgetBrand
          color="linkedin"
          right-header="500+"
          right-footer="contracts"
          left-header="292"
          left-footer="feeds"
        >
          <CIcon
            name="cib-linkedin"
            height="52"
            class="my-4"
          />
          <CChartLineSimple
            class="c-chart-brand"
            background-color="rgba(255,255,255,.1)"
            :data-points="[78, 81, 80, 45, 34, 12, 40]"
            label="Contracts"
            labels="months"
          />
        </CWidgetBrand>
       </CCol>
       <CCol md="3" sm="6">
        <CWidgetBrand
          right-header="12"
          right-footer="events"
          left-header="4"
          left-footer="meetings"
          color="warning"
        >
          <CIcon
            name="cil-calendar"
            height="52"
            class="my-4"
          />
          <CChartLineSimple
            class="c-chart-brand"
            background-color="rgba(255,255,255,.1)"
            :data-points="[35, 23, 56, 22, 97, 23, 64]"
            label="Followers"
            labels="months"
          />
        </CWidgetBrand>
      </CCol>
    </template> -->
    <template>
      <CCol md="7" sm="6">
        <div class="qstart-box" v-if="dashboard.deposit == 0">
          <a target="_blank" :href="`${ori}/user-guide/${getLa}/examples/container.html`" class="ael">{{ $t("Dashboard.QuidckStart") }}</a>
        </div>
        <div class="item-box i-boxs">
          <div class="item-title-box">
            <div style="font-weight: bold">
              {{ $t("Dashboard.SecureYourAccount") }}
            </div>
          </div>
          <div>
            <div class="item align">
              <div class="con-box">
                <div class="num" :style="`color:${formatNum(dashboard.balance) < 0 ? 'red' : ''}`">${{ formatNum(dashboard.balance) }}</div>
                <div class="dec">{{ $t("Dashboard.CurrentBalance") }}</div>
              </div>
              <div class="line"></div>
              <div class="con-box">
                <div class="num">${{ formatNum(dashboard.deposit) }}</div>
                <div class="dec">{{ $t("Dashboard.TotalEntry") }}</div>
              </div>
              <div class="line"></div>
              <div class="con-box">
                <div class="num">${{ formatNum(info.guarantee) }}
                  <el-tooltip placement="top">
                    <div slot="content">
                      <div>{{ $t("Dashboard.GuaranteeDepositTip") }}</div>
                    </div>
                    <i style="cursor:pointer;font-size:18px;vertical-align:0.5px;color:#DDA64F" class="el-icon-question"></i>
                  </el-tooltip>
                </div>
                <div class="dec">
                  <a class="dec" @click="showDig" href="javascript:;" style="cursor:pointer">{{ $t("Dashboard.GuaranteeDeposit") }}</a>
                </div>
              </div>
            </div>
            <div class="transf">
            <!-- <el-button class="hv" @click="toFunds" size="small" style="color: rgb(0,190,164);">{{ $t('Funds.TransforFunds') }}</el-button> -->
            <!-- <button @click="toFunds" type="button" class="btn btn-ghost-success">{{ $t('Funds.TransforFunds') }}</button> -->
            <button class="cur hv" @click="toFunds">{{ $t('Funds.TransforFunds') }}</button>
          </div>
          </div>
          <div class="item-footer-box">
            <el-tooltip placement="bottom">
              <div slot="content">
                {{ $t("Dashboard.Tip1.Row1") }} <br/>
                {{ $t("Dashboard.Tip1.Row2") }} <br/>
                {{ $t("Dashboard.Tip1.Row3") }} <br/>
                {{ $t("Dashboard.Tip1.Row4") }} <br/>
                {{ $t("Dashboard.Tip1.Row5") }}
              </div>
              <div class="footer-text">
                {{ $t("Dashboard.HowToCalculateTheAboveData") }}
              </div>
            </el-tooltip>
          </div>
        </div>

        <div class="item-box i-boxs">
          <div class="item-title-box">
            <div style="font-weight: bold">
              {{ $t("Dashboard.TimelyMonitorCardUsage") }}
            </div>
          </div>
          <div>
            <div class="item align">
              <div class="con-box">
                <div class="num">${{ formatNum(dashboard.spent) }}</div>
                <div class="dec">
                  {{ $t("Dashboard.ConsumptionInRecent24Hours") }}
                </div>
              </div>
              <div class="line"></div>
              <div class="con-box">
                <div class="num">{{ dashboard.cards }}</div>
                <div class="dec">{{ $t("Dashboard.NewInRecent24Hours") }}</div>
              </div>
            </div>
          </div>
          <div class="item-footer-box">
            <el-tooltip placement="bottom">
              <div slot="content">
                {{ $t("Dashboard.Tip2.Row1") }} <br/>
                {{ $t("Dashboard.Tip2.Row2") }} <br/>
                {{ $t("Dashboard.Tip2.Row3") }} <br/>
                {{ $t("Dashboard.Tip2.Row4") }} <br/>
                {{ $t("Dashboard.Tip2.Row5") }} <br/>
                {{ $t("Dashboard.Tip2.Row6") }} 
              </div>
              <div class="footer-text">
                {{ $t("Dashboard.HowToCalculateTheAboveData") }}
              </div>
            </el-tooltip>
          </div>
        </div>

        <div class="card-box-info">
          <CCol style="padding-left:0;padding-right:0" md="3" sm="6">
          <div class="c-box-item">
            <div class="item-value">{{ info.card_num }}</div>
            <div class="item-info">{{ $t("Dashboard.ActiveCards") }}</div>
            <div class="item-dec">{{ $t("Dashboard.TheAmountOfActiveCards") }}</div>
          </div>
        </CCol>
        <CCol style="padding-left:0;padding-right:0" md="3" sm="6">
          <div class="c-box-item mf" style="padding-right: 0;">
            <div class="item-value">${{ formatNum(info.card_balance) }}</div>
            <div class="item-info">{{ $t("Dashboard.TotalCardBalance") }}</div>
            <div class="item-dec">{{ $t("Dashboard.CardsTotalAvailableAmount") }}</div>
          </div>
        </CCol>
        <CCol style="padding-left:0;padding-right:0" md="3" sm="6">
          <div class="c-box-item mf">
            <div class="item-value">{{ info.auth_num }}</div>
            <div class="item-info">{{ $t("Dashboard.AuthTimes") }}</div>
            <div class="item-dec">{{ $t("Dashboard.AuthorizationTimes") }}</div>
          </div>
        </CCol>
        <CCol style="padding-left:0;padding-right:0" md="3" sm="6">
          <div class="c-box-item mf">
            <div class="item-value" :style="toPoint(info.decline_rate) > 0.2 ? 'color: red' : ''">{{ info.decline_rate }} 
              <el-tooltip placement="top" v-if="toPoint(info.decline_rate) > 0.2">
              <div slot="content">
                <div>{{ $t("Dashboard.DeclineRateTip") }}</div>
              </div>
              <i style="cursor:pointer" class="el-icon-warning" v-if="toPoint(info.decline_rate) >= 0.2"></i>
            </el-tooltip>
              
            </div>
            <div class="item-info">{{ $t("Dashboard.DeclineRate") }} </div>
            <div class="item-dec">{{ $t("Dashboard.DeclinedAuthTimes") }}</div>
          </div>
        </CCol>
        </div>

      </CCol>
      <!-- <CCol md="4" sm="6">
        <CWidgetBrand
          color="linkedin"
          right-header="$500,000+"
          right-footer="this month"
          left-header="$500,000+"
          left-footer="lifetime"
          class="c-boxs"
          style="color:#1675D4"
        >
        </CWidgetBrand>
       </CCol> -->
      <!-- <CCol md="6" sm="6">

        <CWidgetBrand
          color="linkedin"
          right-header="500+"
          right-footer="contracts"
          left-header="292"
          left-footer="feeds"
          class="c-boxs"
        >
        </CWidgetBrand>
       </CCol> -->
      <CCol md="5" sm="6">
        <MyChartDoughnut
          :chartData="cList"
          :total="total_spent2"
        ></MyChartDoughnut>
      </CCol>
    </template>
    <!-- 支付保證金彈窗 -->
    <div>
      <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="30%">
      <span slot="title">
        <el-alert type="warning">
          <div slot="title">
            <div>{{ $t('VirtualCard.Create.GuaranteeDialog.DialogTitleTip') }}</div>
            <!-- <div>428837 / 540524 / 222929 / 222934 / 472593 / 557271 / 485932 / 404038 / 559666 / 556150</div> -->
            <div style="padding:5px 0">
              <span>{{ $t('VirtualCard.Create.GuaranteeDialog.UpdateTo5') }}</span>
              <span>428837 / 540524 / 222929 / 222934 / 472593 / 557271 / 485932 / 404038 / 556150 / 559666 / 441112 / 524897</span>
            </div>
            <div>
              <span>{{ $t('VirtualCard.Create.GuaranteeDialog.UpdateTo10') }}</span>
              <span>529587 / 538738 / 526144 / 418122 / 417969</span>
            </div>
          </div>
        </el-alert>
      </span>
      <span>{{ $t('VirtualCard.Create.GuaranteeDialog.GuaranteeDeposit') }}</span><span style="font-weight:bold">$100</span>
      
      <span slot="footer" class="dialog-footer">
        <div style="text-align:left">
          <el-alert>
          <div slot="title">
            <div>{{ $t('VirtualCard.Create.GuaranteeDialog.DialogFooterTip1') }}</div>
            <div>{{ $t('VirtualCard.Create.GuaranteeDialog.DialogFooterTip2') }}</div>
          </div>
        </el-alert>
        </div>
        <div style="margin-top:10px">
          <el-button class="qxclass" @click="dialogVisible = false">{{ $t('VirtualCard.Create.GuaranteeDialog.Cancel') }}</el-button>
          <el-button style="background:#00BEA4;color:#fff" type="primary" @click="payNow">{{ $t('VirtualCard.Create.GuaranteeDialog.PayNow') }}</el-button>
        </div>
      </span>
    </el-dialog>
    </div>
  </CRow>
</template>

<script>
// import { CChartLineSimple } from "../charts/index.js";
// import { CChartDoughnut } from '@coreui/vue-chartjs'
import MyChartDoughnut from "../../components/MyChartDoughnut.vue";

import { CTooltip } from "@coreui/vue";
import { getHome, guaranteeDeposit } from "../../api/http.js";

// import store from "../../store"

export default {
  name: "WidgetsBrand",
  components: {
    // CChartLineSimple,
    // CChartDoughnut
    MyChartDoughnut,
    CTooltip,
  },
  props: {
    noCharts: Boolean,
  },
  data() {
    return {
      cList: [],
      dialogVisible: false,
      dashboard: {
        deposit: 0, //总入账
        balance: 0, //当前余额
        spent: 0, //近24小时消费
        cards: 0, //近24小时新建
      },
      info: "",
      total_spent2: "",
      ori: "",
      lg: ""
      // total_spent: 0
    };
  },
  watch: {
    
  },
  methods: {
    // 百分比轉爲小數
    toPoint(percent) {
      let v = percent || "0%"
      var fnum = v.replace("%", "");
        console.log('fnum==>', fnum)
      fnum = fnum / 100;
      return fnum;
    },
    formatNum(value) {
      if (!value && value !== 0) return 0;

      let str = value.toString();
      let reg =
        str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    },
    toFunds() {
      this.$router.push("/funds/funds")
    },
    // 點擊支付保證金
    showDig() {
      this.dialogVisible = true
    },
    // 現在支付
    payNow() {
      let tm = null
      let data = {
        type: 'guarantee_deposit',
        uid: localStorage.getItem('uid')
      }
      guaranteeDeposit(data).then((res) => {
        if(res.code == 200) {
          this.dialogVisible = false
          this.$message.success(res.message)
          clearTimeout(tm)
          tm = setTimeout(() => {location.reload()},1500)
        }else {
          this.$message.error(res.message)
        }
      })
    },
    getData() {
      let data = {
        type: "dashboard",
        uid: window.localStorage.getItem("uid"),
      };
      getHome(data).then((res) => {
        console.log("res==>", res);
        this.$store.commit("getDashboardData", res.data);
        if (res.code == 200) {
          this.dashboard.deposit = res.data.deposit;
          this.dashboard.balance = res.data.balance;
          this.dashboard.spent = res.data.spent24;
          this.dashboard.cards = res.data.cards24;
          this.total_spent = res.data.total_spent;
          this.info = res.data
          // this.$nextTick(() => {
          //   this.total_spent = res.data.total_spent
          // })
          // this.cList = res.data.merchant_spent
          res.data.merchant_spent.forEach((item) => {
            this.cList.push({
              name: item.name,
              value: item.amt,
            });
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },

  created() {
    this.ori = window.location.origin
    // this.$store.dispatch('getUnreadCount')
    // this.lg = window.localStorage.getItem("lang")
    // this.getData()
    // this.cList.push(
    //   { value: 0, name: "Search Engine Search Engine Search Engine" },
    //   { value: 10, name: "Direct Direct Direct Direct" },
    //   { value: 20, name: "Email Email Email Email Email" },
    //   { value: 240, name: "Union Ads Union Ads Union Ads Union Ads" },
    //   { value: 0, name: "Video Ads Video Ads Video Ads Video Ads Video Ads" }
    // );
  },
  mounted() {
    this.getData();
  },
  computed: {
    getLa() {
      // return localStorage.getItem("lang")
      let la = this.$i18n.locale
      if(la == 'en') {
        return 'en-US'
      }else {
        return this.$i18n.locale
      }
    },
    total_spent: {
      get() {
        return;
      },
      set(val) {
        console.log("val==>", val);
        if (val !== "") {
          this.total_spent2 = val;
        }
      },
    },
  },
};
</script>

<style scoped>
.qxclass:hover{
  background-color: #00bea41c;
  color: #00BEA4
}
.qstart-box{
  text-align: right;
}
.ael{
  color: #1675D4;
}
.card-box-info{
  height: auto;
  background: #fff;
  border-radius: 5px;
  display: flex;
}
.c-box-item{
    padding: 1rem .88rem;
    background: #fff;
    border-radius: 5px;
    text-align: center;
}
.item-value{
  font-size: 22px;
  font-weight: bold;
  color: #1675d4;
}
.item-info{
  font-weight: bold;
  font-size: 13px;
  padding-bottom: .2rem;
  color: #7b7794;
}
.item-dec{
  font-size: .875em;
  color: #2c384aae;
}
.mf{
  margin-left: 0.4rem
}
.cur{
  outline: none;
  border: none;
  color: #00BEA4;
  background: transparent;
  padding: 6px 12px;
  font-weight: bold;
}
.hv:hover{
  background: rgb(0,190,164);
  color: #fff;
  border-radius: 0.375rem;
}
.transf{
  margin: .5rem 0;
  text-align: center;
}
.c-chart-brand {
  position: absolute;
  width: 100%;
  height: 100px;
}
.c-boxs {
  padding: 20px;
  margin-top: 10px;
  margin-left: 20px;
}
.item-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: row;
  min-width: 0;
  /* margin-bottom: 1.5rem; */
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: #d8dbe0;
  flex-direction: column;
  align-items: center;
}
.item {
  width: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  /* margin-left: -15px; */
  align-items: center;
}
.i-boxs {
  /* padding: 32px; */
  /* margin-top: 10px; */
  /* margin-left: 20px; */
  padding: 0.4rem 0;
  margin-bottom: 0.3rem;
}
.line {
  width: 0.01rem;
  height: 2.5rem;
  background-color: #eaeaea;
}
.align {
  text-align: center;
  padding-bottom: 0.2rem;
  /* padding: 0.8rem; */
}
.con-box {
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}
.num {
  color: #1675d4;
  font-size: 1.42rem;
  font-weight: bold;
}
.dec {
  color: #7a838b;
  /* text-transform: uppercase; */
  /* font-size: 80%; */
  font-size: 14px;
  font-weight: 400;
}
.item-footer-box {
  color: #1f7ad4;
}
.footer-text {
  font-size: 13px;
  cursor: pointer;
  color: #1f7ad4;
}
.item-title-box {
  color: #7b7794;
  font-size: 16px;
}
.lg-p {
  padding: 0 7.4rem;
}

@media screen and (max-width: 520px) {
  .sm-qu-padding {
    padding: 0;
  }
}
</style>
